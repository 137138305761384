import { SQL } from './Database';
import { WaitObject } from './Wait';

/*
const application_endpoints_list = [
    {endpoint:"default", servers: ["https://localhost:7072/", "https://localhost:7072/", "https://localhost:7072/"]},
    {endpoint:"api/Auth/Login", servers: ["https://localhost:7072/", "https://localhost:7072/", "https://localhost:7072/"]}
];

const application_servers_list = [
    "https://localhost:7072/",
    "https://localhost:7072/",
    "https://localhost:7072/",
    "https://localhost:7072/"
];
*/


const application_endpoints_list = [
    {endpoint:"default", servers: ["https://warmup.agenciavitrae.com.br/", "https://warmup.agenciavitrae.com.br/", "https://warmup.agenciavitrae.com.br/"]},
    {endpoint:"api/Auth/Login", servers: ["https://warmup.agenciavitrae.com.br/", "https://warmup.agenciavitrae.com.br/", "https://warmup.agenciavitrae.com.br/"]}
];


const application_servers_list = [
    "https://warmup.agenciavitrae.com.br/",
    "https://warmup.agenciavitrae.com.br/",
    "https://warmup.agenciavitrae.com.br/",
    "https://warmup.agenciavitrae.com.br/"
];



export const GetServer = async(server_index) => {
    const db = new SQL();

    await db.Open("warmup").then(()=>{
        //console.log("Db opened");
    }).catch(()=>{
       // console.log("Db not opened");
    });

    //await db.Clear("cache");
    
    
    await db.Create("cache", "id INTEGER PRIMARY KEY, endpoint VARCHAR, content TEXT").then(()=>{
        //console.log("Tb created");
    }).catch(()=>{
        //console.log("Tb not created");
    });

    
    let id_server = rIndex(0, application_servers_list.length-1);
    //console.log("id_server", id_server);
    let _server = ((server_index === undefined || server_index === null || server_index === "") ? application_servers_list[id_server] : application_servers_list[server_index]);

    let ourl = window.location.href;
    if (ourl.indexOf("localhost") > -1) {
        window.localStorage.setItem('app_server',_server);
    } else {
        window.localStorage.setItem('app_server', _server);

    }
}



export const AppServer = (endpoint = "") => {
    if(endpoint === "") {
        let _server = window.localStorage.getItem('app_server');
        if (_server !== undefined && _server !== null && _server !== "") {
            //console.log("app_server 1", _server);
            return _server;
        } else {
            let id_server = rIndex(0, application_servers_list.length-1);
            _server = application_servers_list[id_server];
            window.localStorage.setItem('app_server', _server);

            if (_server !== undefined && _server !== null && _server !== "") {
                //console.log("app_server 2", _server, id_server);

                return _server;
            } else {
                //console.log("app_server 3", _server);
                return application_servers_list[0];
            }
        }
    } else {
        let _endpoint = application_endpoints_list.find(e => e.endpoint === endpoint);
        if(!_endpoint) {
            _endpoint = application_endpoints_list.find(e => e.endpoint === "default");
        }

        if(_endpoint) {
            //console.log(_endpoint);
            let id_server = rIndex(0, _endpoint.servers.length-1);
            //console.log("app_server", _endpoint,endpoint, _endpoint.servers[id_server]);
            return _endpoint.servers[id_server];
        } else {
            return "";
        }
    }
}





const rIndex = (min, max) => {
    min = Math.floor(min);
    max = Math.ceil(max) + 0.9;
    //return Math.floor(Math.random() * (max - min)) + min;
    return  Math.floor((Math.random() * 1000000)%(max - min)) + min;
}
