/* eslint-disable no-useless-constructor */
/* eslint-disable no-restricted-globals */
import { WaitObject } from '../scripts/Wait';

export class SQL {
    self = this;
    db = null;
    opened = false;
    created = false;

    constructor() {
        
    }


    Open = async (name,  size = 4) => {
        if (window.openDatabase) {
            self.db = window.openDatabase(name, "1.0", name, (size * 1024 * 1024), () => {
                self.opened  = true;
                console.log('db successfully opened');
            });
        } else {
            console.log("Open error!!")
        }
    }



    Create = async (table, fields) => {
        if (window.openDatabase) {
            await self.db.transaction((tx) => {
                tx.executeSql("CREATE TABLE IF NOT EXISTS " + table + " (" + fields + ")");
                self.created = true;
            }, (transaction, error) => {
                console.log('Query failed: ' + error.message);
            });
        } else {
            console.log("Create error!")
        }

        return self.initialized;
    }



    Clear = async (table) => {
        try {
            await self.db.transaction((tx) => {
                tx.executeSql("DROP TABLE " + table, [], (transaction, rs) => { }, (transaction, error) => { });
            });
            return true;
        } catch(e) { return false}
    }


    /*Query = async(queryStr, args, callback) => {
        await self.db.transaction((tx) => {
            tx.executeSql(queryStr, args, function (transaction, rs) {
                var result = [];
                for (var i = 0; i < rs.rows.length; i++) {
                    //row = rs.rows.item(i);
                    result.push(rs.rows.item(i));
                }
                if (callback) callback(result);
            }, (transaction, error) => { console.log('Query failed: ' + error.message); });
        });
    }*/


    Query = async(queryStr, args) => {
        return WaitObject(self.db).then(async() => { 
            return new Promise((resolve, reject) => { 
                self.db.transaction((tx) => {
                    tx.executeSql(queryStr, args, function (transaction, rs) {
                        let result = [];
                        for (var i = 0; i < rs.rows.length; i++) {
                            result.push(rs.rows.item(i));
                        }
                        resolve(result);
                    }, function(error) { 
                        reject(error);
                    });
                });
            });
        });


        /*if(self.db) {
            return new Promise((resolve, reject) => { 
                self.db.transaction((tx) => {
                    tx.executeSql(queryStr, args, function (transaction, rs) {
                        let result = [];
                        for (var i = 0; i < rs.rows.length; i++) {
                            result.push(rs.rows.item(i));
                        }
                        resolve(result);
                    }, function(error) { 
                        reject(error);
                    });
                });
            });
        } else {
            WaitObject(self.db).then(async() => { 
                return new Promise((resolve, reject) => { 
                    self.db.transaction((tx) => {
                        tx.executeSql(queryStr, args, function (transaction, rs) {
                            let result = [];
                            for (var i = 0; i < rs.rows.length; i++) {
                                result.push(rs.rows.item(i));
                            }
                            resolve(result);
                        }, function(error) { 
                            reject(error);
                        });
                    });
                });
            });
        }*/


    };

     

    ToJSON = (j) => {
        var dq = '"';
        var json = "{";
        var last = Object.keys(j).length;
        var count = 0;
        for (let x in j) {
            json += dq + x + dq + ":" + dq + j[x] + dq;
            count++;
            if (count < last)
                json += ",";
        }
        json += "}";
        return json;
    }
}

