import React, {  useState,useRef, useEffect }  from 'react';
import { Sleep, WaitObject } from '../../scripts/Wait';
import { Request } from '../../scripts/Request';
import { FormGenerator } from '../Form/FormGenerator';
import { useParams } from 'react-router-dom';
import './index.css'

export default function Warmup() {
    const [pageLoading, setPageLoading] = useState(true);
    const _formUserRef = useRef();
    const _formLoadedRef = useRef();
    const loadPrestineRef = useRef(false);

    let { WarmupId } = useParams();

    //#region Effects
    /*useEffect(() => {
        Init();
    }, []);*/
    //#endregion Effects


    //#region Init
    /*const Init = async() => {
        if(!loadPrestineRef.current){
            loadPrestineRef.current = true;
            LoadWarmUp(1);
        }
    }*/
    //#endregion Init


    //#region Loaders
    /*const LoadWarmUp = (id) => {
        Promise.resolve(new Request().Run("api/Warmup/Load?id="+id, "GET", ""))
            .then((data) => {
               console.log(data);
            });
    }*/
    //#endregion Loaders

    
    //#region Form Handlers
    const click_add_diferencial = async() => {
        await _formUserRef.current.Push("diferencial");
        Preload();
    }

    const click_add_concorrente = async() => {
        await _formUserRef.current.Push("concorrente");
        Preload();
    }


    const modelo = value => {
        WaitObject(_formUserRef.current).then(async() => {
            _formUserRef.current.SetObject("modelo_outro", {visible:(parseInt(value) === 13)});
            let _modelo = _formUserRef.current.GetObject("modelo").type.options.find(o => o.id === parseInt(value));
            _formUserRef.current.SetObject("div_model_description", {height: _modelo.height});
            document.getElementById("div_model_description").innerHTML = _modelo.description;
       });
    }

    const socios = (value) => {
        _formUserRef.current.Push("socio", value);
    }

    const investimentos = value => {
        _formUserRef.current.Push("investimento", value);
    }

    const area = value => {
        _formUserRef.current.SetObject("outro", {visible:(parseInt(value) === 60)});
    }

    const maturidade = value => {
        if(parseInt(value) === 1) {
            _formUserRef.current.SetObject("maturidade_conceito", {visible:true});
            _formUserRef.current.SetObject("maturidade_desenvolvimento", {visible:false});
            _formUserRef.current.SetObject("maturidade_concluido", {visible:false});
            _formUserRef.current.SetObject("clientes", {visible:false});
        } else if(parseInt(value) === 2) {
            _formUserRef.current.SetObject("maturidade_conceito", {visible:false});
            _formUserRef.current.SetObject("maturidade_desenvolvimento", {visible:true});
            _formUserRef.current.SetObject("maturidade_concluido", {visible:false});
            _formUserRef.current.SetObject("clientes", {visible:false});
        } else if(parseInt(value) === 3) {
            _formUserRef.current.SetObject("maturidade_conceito", {visible:false});
            _formUserRef.current.SetObject("maturidade_desenvolvimento", {visible:false});
            _formUserRef.current.SetObject("maturidade_concluido", {visible:true});
            _formUserRef.current.SetObject("clientes", {visible:true});
        }
    }

    const escalavel = value => {
        if(parseInt(value) === 1) {
            _formUserRef.current.SetObject("escalavel_software", {disabled:false});
            _formUserRef.current.SetObject("escalavel_materiaprima", {disabled:false});
            _formUserRef.current.SetObject("escalavel_maodeobra", {disabled:false});
            _formUserRef.current.SetObject("escalavel_infraestrutura", {disabled:false});
            _formUserRef.current.SetObject("escalavel_investimento", {disabled:false});
            _formUserRef.current.SetObject("escalavel_publicidade", {disabled:false});
        } else if(parseInt(value) === 0) {
            _formUserRef.current.SetObject("escalavel_software", {disabled:true, checked: false});
            _formUserRef.current.SetObject("escalavel_materiaprima", {disabled:true, checked: false});
            _formUserRef.current.SetObject("escalavel_maodeobra", {disabled:true, checked: false});
            _formUserRef.current.SetObject("escalavel_infraestrutura", {disabled:true, checked: false});
            _formUserRef.current.SetObject("escalavel_investimento", {disabled:true, checked: false});
            _formUserRef.current.SetObject("escalavel_publicidade", {disabled:true, checked: false});
        }
    }

    const propriedade_dominio = value => {
        _formUserRef.current.SetObject("dominio", {disabled:!value, value: ""});
    }


    const concorrentes = value => {
        _formUserRef.current.SetObject("concorrente", {visible:parseInt(value)===1});
        _formUserRef.current.SetObject("add_concorrente", {visible:parseInt(value)===1});
    }


    const faturamento = value => {
        _formUserRef.current.SetObject("faturamento_valor", {visible:parseInt(value)===1});
    }


    const OnFormLoaded = async(fields) => {
       /*Sleep(200).then(() => {
            Preload();
       });*/
       //console.log("OnFormLoaded");

       WaitObject(_formUserRef.current).then(async() => {
            Preload();

            let warmup_data = await new Request().Run("api/Warmup/Load?id="+WarmupId, "GET", "");
            await _formUserRef.current.Load(warmup_data);
       });
    }


    const Exec = (func, value) => {
        try {
            eval(func)(value);
        } catch (e) { }
    }
    //#endregion Form Handlers


    //#region Handlers
    const _HandleSubmit = async (formname, event) => {
        event.preventDefault();

        //_formUserRef.current.Test("","");
        _formUserRef.current.Post("api/Warmup/Save", {warmup_id: WarmupId});
    }
    //#endregion Handlers


    //#region Functions
    const Preload = () => {
        let _modelo = _formUserRef.current.GetObject("modelo").type.options[0];
        _formUserRef.current.SetObject("div_model_description", {height: _modelo.height});
        document.getElementById("div_model_description").innerHTML = _modelo.description;
    }
    //#endregion Functions

    return (
        <>
          <main id="Reativar">
            <div className="container">
                <br />
                <center><span className="topTitle"><h2>Warmup Form</h2></span></center>

                <form name="fuser" onSubmit={(event) => _HandleSubmit('reativar', event)} autoComplete="off" id="form-contact" className="" noValidate>
                    <div className="row">
                        <div className="col-md-12 pt-1 pb-1">
                            <FormGenerator ref={_formUserRef} name={'cadastro'} url={'/forms/warmup.json'} execRef={Exec}></FormGenerator>
                            <div style={{clear:'both'}}></div>
                            <center>
                                <hr />
                                <button className="btn btn-lg btn-primary mt-3" type="submit">Enviar formulario</button>
                            </center>
                            <br />
                        </div> 
                    </div>
                </form>
            </div>
    </main>
        </>
    );
}