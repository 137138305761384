import React, { useEffect,  useState, useRef, Suspense, lazy, useContext } from 'react';
import { Routes, Route,  useNavigate, useLocation, useParams } from 'react-router-dom';
import { GetServer } from './scripts/Loadbalancer';
import Home from './components/Home';
import Warmup from './components/Warmup';

import Layout from './Layout';




export default function RouteManager() {
    useEffect(() => {
      GetServer();
    }, [])



    return (
    
      <Suspense fallback={<div>Carregando...</div>}>
        <Layout>
            <Routes>
                <Route exact path="/" element={<Home />} />
                <Route exact path="/Home" element={<Home />} />
                <Route exact path="/Warmup" element={<Warmup />} />
                <Route exact path="/Warmup/:WarmupId" element={<Warmup />} />
            </Routes>
        </Layout>
      </Suspense>
   
  );
}