import React, { useState, useEffect } from 'react';
import RouteManager from './RouteManager';
import { BrowserRouter as Router} from 'react-router-dom';


import { AuthProvider } from './Context/AuthContext';
import './App.css';

function App() {
  return (
    <>
    <Router>
      <AuthProvider>
        <RouteManager />
      </AuthProvider>
    </Router>
  </>
  );
}

export default App;
