export const Wait = async (element, timeout=10000) => {
    let _self = document;

    return new Promise(resolve => { 
        let _c = 0;
        let _wait = setInterval(()=> {
            if(_self.querySelector(element)) {
                clearInterval(_wait);
                _c = 0;
                resolve(_self.querySelector(element));
            } else {
                if(_c >=  (timeout/100)) {
                    clearInterval(_wait);
                    console.error("Element timeout", element);
                    _c = 0;
                }
                _c++;
            }
        }, 100);
    });
}


export const WaitObject = async (element, timeout=10000) => {
    return new Promise(resolve => { 
        let _c = 0;
        let _wait = setInterval(()=> {
            if(element){
                clearInterval(_wait);
                _c = 0;
                resolve(element);
            } else {
                if(_c >=  (timeout/100)) {
                    clearInterval(_wait);
                    console.error("Element timeout", element);
                    _c = 0;
                }
                _c++;
            }
        }, 100);
    })
}


export const Sleep = async (ms=1000) => {
    return new Promise(resolve => setTimeout(resolve, ms));
}